import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/layout";
import { Row, Col, Card, Button, Image } from 'react-bootstrap';
import Breakfast from "./modals/breakfast/breakfast";
import Lunch from "./modals/lunch/lunch";
import Dinner from "./modals/dinner/dinner";
import Snacks from "./modals/snacks/snacks";
import { BarChart } from '@mui/x-charts/BarChart';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Prev from "../../../assets/images/prev.png";
import Next from "../../../assets/images/next.png";
import AddWater from "../../../assets/images/add-water.png";
import MinusWater from "../../../assets/images/minus-water.png";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { createWaterAsync, getDailyMealLogAsync } from "slices/meal-logging/mealSlice";
import { RootState } from "../../../store/store";
import { DailyMealLog, MealData, WaterData } from "services/meal-logging/mealService";


function Overview() {
    const dispatch = useAppDispatch();
    const dailyMealLog = useAppSelector((state) => state.meal.getDailyMealLogResponse);

    const access_token = useAppSelector((state: RootState) => state.auth.accessToken || "");
    const [breakfastModalOpen, setbreakfastModalOpen] = useState<boolean>(false);
    const handleOpenbreakfastModal = () => setbreakfastModalOpen(true);
    const handleClosebreakfastModal = () => setbreakfastModalOpen(false);

    const [lunchModalOpen, setlunchModalOpen] = useState<boolean>(false);
    const handleOpenlunchModal = () => setlunchModalOpen(true);
    const handleCloselunchModal = () => setlunchModalOpen(false);

    const [dinnerModalOpen, setdinnerModalOpen] = useState<boolean>(false);
    const handleOpendinnerModal = () => setdinnerModalOpen(true);
    const handleClosedinnerModal = () => setdinnerModalOpen(false);

    const [snackModalOpen, setsnackModalOpen] = useState<boolean>(false);
    const handleOpensnackModal = () => setsnackModalOpen(true);
    const handleClosesnackModal = () => setsnackModalOpen(false);
    const totalCapacity = 5000; // Total capacity in milliliters (5 liters)
    const [currentWater, setCurrentWater] = useState(0); // Current water in milliliters

    const increaseWater = () => {
        if (currentWater < totalCapacity) {
            setCurrentWater(prev => Math.min(prev + 500, totalCapacity)); // Increment by 500ml
        }
    };

    const decreaseWater = () => {
        if (currentWater > 0) {
            setCurrentWater(prev => Math.max(prev - 500, 0)); // Decrement by 500ml
        }
    };

    const waterLevel = (currentWater / totalCapacity) * 100; // Calculate percentage
    const handleSubmit = () => {
        const createWaterData: WaterData = {
            amount_taken: currentWater / 1000,
            unit: "l",
        };

        dispatch(createWaterAsync({ access_token, createWaterData }));
    };
    const userId = useAppSelector((state: RootState) => state.auth.signInResponse?.User?.id);
    console.log("userId", userId)
    const userBmr = useAppSelector((state: any) => state.auth.bmr);
    console.log(userBmr)
    const roundedUserBmr = userBmr ? Math.round(userBmr) : 0;

    const breakfastCalorieBudget = roundedUserBmr ? roundedUserBmr * 0.25 : 0;
    const roundedbreakfastCalorie = breakfastCalorieBudget ? Math.round(breakfastCalorieBudget) : breakfastCalorieBudget;
    const lunchCalorieBudget = roundedUserBmr ? roundedUserBmr * 0.35 : 0;
    const roundedlunchCalorie = lunchCalorieBudget ? Math.round(lunchCalorieBudget) : lunchCalorieBudget;
    const dinnerCalorieBudget = roundedUserBmr ? roundedUserBmr * 0.30 : 0;
    const roundeddinnerCalorie = dinnerCalorieBudget ? Math.round(dinnerCalorieBudget) : dinnerCalorieBudget;
    const snackCalorieBudget = roundedUserBmr ? roundedUserBmr * 0.10 : 0;
    const roundedsnackCalorie = snackCalorieBudget ? Math.round(snackCalorieBudget) : snackCalorieBudget;

    const dailyTargets = {
        proteins: 175, // grams
        fats: 78,      // grams
        carbs: 325     // grams
    };

    const [currentIntake, setCurrentIntake] = useState({
        proteins: 0,
        fats: 0,
        carbs: 0,
        calories: 0,
    });
    const [mealIntake, setMealIntake] = useState({
        breakfastCalories: 0,
        lunchCalories: 0,
        dinnerCalories: 0,
        snackCalories: 0,
    });


    useEffect(() => {
        if (dailyMealLog?.data) {
            const intake = {
                proteins: 0,
                fats: 0,
                carbs: 0,
                calories: 0,
            };

            const mealTypes: (keyof DailyMealLog)[] = ['breakfast', 'lunch', 'dinner', 'snack'];
            const mealIntakeTemp = {
                breakfastCalories: 0,
                lunchCalories: 0,
                dinnerCalories: 0,
                snackCalories: 0,
            };
            mealTypes.forEach(mealType => {
                const meals: MealData[] = dailyMealLog.data[mealType] || [];
                meals.forEach((meal) => {
                    intake.proteins += meal.meal_nutrition.protein;
                    intake.fats += meal.meal_nutrition.fat;
                    intake.carbs += meal.meal_nutrition.carbohydrates;
                    intake.calories += meal.meal_nutrition.calories;

                    mealIntakeTemp[`${mealType}Calories`] += meal.meal_nutrition.calories;

                });
            });

            setCurrentIntake(intake);
            setMealIntake(mealIntakeTemp);

        }
    }, [dailyMealLog]);


    const proteinProgress = (currentIntake.proteins / dailyTargets.proteins) * 100;
    const fatProgress = (currentIntake.fats / dailyTargets.fats) * 100;
    const carbProgress = (currentIntake.carbs / dailyTargets.carbs) * 100;

    useEffect(() => {
        dispatch(getDailyMealLogAsync({ access_token, id: String(userId) }));
    }, [dispatch, access_token, userId]);

    const breakfastMeals = dailyMealLog?.data?.breakfast || [];
    const lunchMeals = dailyMealLog?.data?.lunch || [];
    const dinnerMeals = dailyMealLog?.data?.dinner || [];
    const snackMeals = dailyMealLog?.data?.snack || [];

    return (
        <div>
            <Layout>
                <div className='p-3 dash-bg'>
                    <h6 className='dash-text-1'><span className='text-color-4'>Pages /</span> Dashboard</h6>
                    <h5 className='dash-text-2'>Dashboard Overview</h5>
                    <Card className='bg-color-2 dash-card-1 border-0 p-3 text-white'>
                        <Row>
                            <Col sm={6}>
                                <h4 className='dash-text-3'>
                                    Hello User,</h4>
                                <p className='dash-text-4'>Have a nice day and don’t forget to take care < br /> of your health!</p>
                            </Col>
                            <Col sm={6}>
                                <h4 className='dash-text-5'>12:58:00 P.M.</h4>
                                <h6 className='dash-text-6'>Saturday 28th September, 2024</h6>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col md={7} sm={12} className='mt-2 py-1'>
                            <Card className=''>
                                <h4 className='dash-text-7 p-2'>Fitness Activity</h4>
                                <div>
                                    <BarChart
                                        xAxis={[{
                                            scaleType: 'band',
                                            data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                        }]}
                                        series={[
                                            { data: [14, 32, 41, 25, 17, 49, 19, 31, 52, 32, 60, 27] },
                                            { data: [32, 60, 51, 55, 39, 27, 10, 43, 39, 58, 35, 49] }
                                        ]}
                                        height={300}
                                        className="band-width"
                                    />
                                </div>
                            </Card>
                        </Col>
                        <Col md={5} sm={12}>
                            <Row className='mt-2'>
                                <Card className='p-2'>
                                    <Row>
                                        <Col md={9} sm={9} xs={9}>
                                            <h4 className='dash-text-7'>Nutrients Indicator</h4>
                                        </Col>
                                        <Col md={3} sm={3} xs={3}>
                                            <div className="float-end">
                                                <Image src={Prev} />
                                                <Image src={Next} className="mx-2" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} sm={4} className="text-center">
                                            <small>{currentIntake.proteins} / {dailyTargets.proteins}</small>
                                            <div className='d-flex justify-content-center my-1'>
                                                <ProgressBar now={proteinProgress} className="custom-progress-bar-4" style={{ height: '4px', width: '100%' }} />
                                            </div>
                                            <p>Proteins</p>
                                        </Col>
                                        <Col md={4} sm={4} className="text-center">
                                            <small>{currentIntake.fats} / {dailyTargets.fats}</small>
                                            <div className='d-flex justify-content-center my-1'>
                                                <ProgressBar now={fatProgress} className="custom-progress-bar-3" style={{ height: '4px', width: '100%' }} />
                                            </div>
                                            <p>Fats</p>
                                        </Col>
                                        <Col md={4} sm={4} className="text-center">
                                            <small>{currentIntake.carbs} / {dailyTargets.carbs}</small>
                                            <div className='d-flex justify-content-center my-1'>
                                                <ProgressBar now={carbProgress} className="custom-progress-bar-2" style={{ height: '4px', width: '100%' }} />
                                            </div>
                                            <p>Carbs</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <small>{currentIntake.calories} / {roundedUserBmr}</small>
                                            <div className='d-flex justify-content-center my-1'>
                                                <ProgressBar
                                                    now={(currentIntake.calories / roundedUserBmr) * 100}
                                                    className="custom-progress-bar-2"
                                                    style={{ height: '4px', width: '100%' }}
                                                />
                                            </div>
                                            <p>Calories</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>
                            <Row className='mt-2'>
                                <Card className='p-2'>
                                    <h3 className='dash-text-18'>Snacks</h3>
                                    <Row className="mt-2">
                                        <Col md={6}>
                                            <ul>
                                                {snackMeals.length > 0 ? (
                                                    snackMeals.map((meal) => (
                                                        <li key={meal.id} className="text-color-8 dash-text-14">{meal.meal__meal_name}</li>
                                                    ))
                                                ) : (
                                                    <li className="text-color-8 dash-text-14">No meals logged.</li>
                                                )}
                                            </ul>
                                        </Col>
                                        <Col md={6}>
                                            <Row className="text-center">
                                                <small>{mealIntake.snackCalories} / {roundedsnackCalorie}</small>
                                                <div className='d-flex justify-content-center'>
                                                    <ProgressBar
                                                        now={
                                                            roundedsnackCalorie > 0
                                                                ? Math.min((mealIntake.snackCalories / roundedsnackCalorie) * 100, 100)
                                                                : 0
                                                        }
                                                        className="custom-progress-bar-2"
                                                        style={{ height: '4px', width: '100%' }}
                                                    />
                                                </div>
                                                <p className="dash-text-17">Estimated Calorie Budget</p>
                                            </Row>
                                            <Row className="d-flex justify-content-center pb-1">
                                                <Button onClick={handleOpensnackModal} className='add-btn bg-color-3'>Add</Button>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} sm={6} xs={12} className='mt-3'>
                            <Card className='px-4 py-3 dash-card-2' style={{ height: '100%' }}>
                                <div className='text-center'>
                                    <h4 className='dash-text-18'>Breakfast</h4>
                                    <Row className="text-center">
                                        <small>{mealIntake.breakfastCalories} / {roundedbreakfastCalorie}</small>
                                        <div className='d-flex justify-content-center'>
                                            <ProgressBar
                                                now={
                                                    roundedbreakfastCalorie > 0
                                                        ? Math.min((mealIntake.breakfastCalories / roundedbreakfastCalorie) * 100, 100)
                                                        : 0
                                                }
                                                className="custom-progress-bar-2"
                                                style={{ height: '4px', width: '100%' }}
                                            />

                                        </div>
                                        <p className="dash-text-17">Estimated Calorie Budget</p>
                                    </Row>
                                    <Row className="text-start px-2">
                                        <ul>
                                            {breakfastMeals.length > 0 ? (
                                                breakfastMeals.map((meal) => (
                                                    <li key={meal.id} className="text-color-8 dash-text-14">{meal.meal__meal_name}</li>
                                                ))
                                            ) : (
                                                <li className="text-color-8 dash-text-14">No meals logged.</li>
                                            )}
                                        </ul>
                                    </Row>
                                </div>
                                <div className="mt-auto text-center">
                                    <Button onClick={handleOpenbreakfastModal} className='add-btn bg-color-3'>
                                        Add
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col md={3} sm={6} xs={12} className='mt-3'>
                            <Card className='px-4 py-3 dash-card-2' style={{ height: '100%' }}>
                                <div className='text-center'>
                                    <h4 className='dash-text-18'>Lunch</h4>
                                    <Row className="text-center">
                                        <small>{mealIntake.lunchCalories} / {roundedlunchCalorie}</small>
                                        <div className='d-flex justify-content-center'>
                                            <ProgressBar
                                                now={
                                                    roundedlunchCalorie > 0
                                                        ? Math.min((mealIntake.lunchCalories / roundedlunchCalorie) * 100, 100)
                                                        : 0
                                                }
                                                className="custom-progress-bar-2"
                                                style={{ height: '4px', width: '100%' }}
                                            />

                                        </div>
                                        <p className="dash-text-17">Estimated Calorie Budget</p>
                                    </Row>
                                    <Row className="text-start px-2">
                                        <ul>
                                            {lunchMeals.length > 0 ? (
                                                lunchMeals.map((meal) => (
                                                    <li key={meal.id} className="text-color-8 dash-text-14">{meal.meal__meal_name}</li>
                                                ))
                                            ) : (
                                                <li className="text-color-8 dash-text-14">No meals logged.</li>
                                            )}
                                        </ul>
                                    </Row>
                                </div>
                                <div className="mt-auto text-center">
                                    <Button onClick={handleOpenlunchModal} className='add-btn bg-color-3'>
                                        Add
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col md={3} sm={6} xs={12} className='mt-3'>
                            <Card className='px-4 py-3 dash-card-2' style={{ height: '100%' }}>
                                <div className='text-center'>
                                    <h4 className='dash-text-18'>Dinner</h4>
                                    <Row className="text-center">
                                        <small>{mealIntake.dinnerCalories} / {roundeddinnerCalorie}</small>
                                        <div className='d-flex justify-content-center'>
                                            <ProgressBar
                                                now={
                                                    roundeddinnerCalorie > 0
                                                        ? Math.min((mealIntake.dinnerCalories / roundeddinnerCalorie) * 100, 100)
                                                        : 0
                                                }
                                                className="custom-progress-bar-2"
                                                style={{ height: '4px', width: '100%' }}
                                            />
                                        </div>
                                        <p className="dash-text-17">Estimated Calorie Budget</p>
                                    </Row>
                                    <Row className="text-start px-2">
                                        <ul>
                                            {dinnerMeals.length > 0 ? (
                                                dinnerMeals.map((meal) => (
                                                    <li key={meal.id} className="text-color-8 dash-text-14">{meal.meal__meal_name}</li>
                                                ))
                                            ) : (
                                                <li className="text-color-8 dash-text-14">No meals logged.</li>
                                            )}
                                        </ul>
                                    </Row>
                                </div>
                                <div className="mt-auto text-center">
                                    <Button onClick={handleOpendinnerModal} className='add-btn bg-color-3'>
                                        Add
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col md={3} sm={6} xs={12} className="mt-3">
                            <Card className="px-4 py-3 d-flex align-items-center dash-card-2" style={{ height: '100%' }}>
                                <div className="text-center">
                                    <h4 className="dash-text-18">Water</h4>
                                    <div className="dash-text-14 mb-1">{`${(currentWater / 1000).toFixed(1)}L / 5L`}</div>
                                    <div className="d-flex align-items-center">
                                        <div className="water-bottle position-relative" style={{ overflow: 'hidden' }}>
                                            <div
                                                className="water-content"
                                                style={{
                                                    height: `${waterLevel}%`,
                                                    background: 'linear-gradient(180deg, #FF0000 7.24%, #05ADDD 78.95%)',
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    width: '100%',
                                                    borderRadius: '30px',
                                                }}
                                            >
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '15%',
                                                    left: '50%',
                                                    transform: 'translateX(-50%)',
                                                    color: '#fff',
                                                }} className="dash-text-14">
                                                    {`${waterLevel.toFixed(0)}%`}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-center ms-3 mt-5">
                                            <Image src={AddWater} onClick={increaseWater} className="mt-2" />

                                            <Image src={MinusWater} onClick={decreaseWater} className="mt-3" />
                                        </div>

                                    </div>
                                    <Row className="d-flex justify-content-center pb-1 mt-2">
                                        <Button className='add-btn bg-color-3' onClick={handleSubmit}>Add</Button>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Breakfast
                    show={breakfastModalOpen}
                    closeModal={handleClosebreakfastModal}
                />
                <Lunch
                    show={lunchModalOpen}
                    closeModal={handleCloselunchModal}
                />
                <Dinner
                    show={dinnerModalOpen}
                    closeModal={handleClosedinnerModal}
                />
                <Snacks
                    show={snackModalOpen}
                    closeModal={handleClosesnackModal}
                />

            </Layout>
        </div>
    )
}

export default Overview