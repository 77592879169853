import axios from 'axios';

axios.interceptors.request.use((config) => {
    if (config.url?.includes('/api/auth/signout/')) {
        return config; // Skip modification
    }
    const token = localStorage.getItem('accessToken');
    console.log(token)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


export interface UserData {
    id: number;
    bmr: number;
    email: string;
    first_name: string;
    last_name: string;
    age: number;
    sex_at_birth: string;
    country_of_residence: string;
    ethnicity: string;
    password: string;
    confirm_password: string;
    terms: boolean;
    user_health_info: {
        weight: number;
        weight_unit: string;
        height: number;
        height_unit: string;
    };
}

export interface SignUpData {
    email: string;
    first_name: string;
    last_name: string;
    age: number;
    sex_at_birth: string;
    country_of_residence: string;
    ethnicity: string;
    password: string;
    confirm_password: string;
    terms: boolean;
    user_health_info: {
        weight: number;
        weight_unit: string;
        height: number;
        height_unit: string;
    };
}

export interface SignUpResponse {
    data: {
        user: UserData;
    };
    message: string;
}

export interface SignInData {
    email: string;
    password: string;
}
export interface SignInResponse {
    User: UserData;
    tokens: {
        access_token: string;
        refresh_token: string;
    };
    message: string;

}

export interface SignOutData {
    refresh_token: string;
}
export interface SignOutResponse {
    message: string;
}
export interface ForgotPasswordData {
    email: string;
}
export interface ForgotPasswordResponse {
    data: {
        email: string;
        token: string;
    } | null;
    message: string;
}
export interface ResetPasswordData {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}
export interface ResetPasswordResponse {
    data: {
        email: string;
    }
    message: string;
}

export const signup = async (values: SignUpData): Promise<SignUpResponse> => {
    const response = await axios.post<SignUpResponse>('https://api.nutient.com/api/accounts/signup/', values);
    return response.data;
}

export const signin = async (values: SignInData): Promise<SignInResponse> => {
    const response = await axios.post<SignInResponse>('https://api.nutient.com/api/auth/signin/', values);
    return response.data;
}

export const signout = async (
    values: SignOutData,
    access_token: string
): Promise<SignOutResponse> => {
    console.log('Access Token:', access_token);
    console.log('Payload:', values);
    try {
        const response = await axios.post<SignOutResponse>(
            'https://api.nutient.com/api/auth/signout/',
            values,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${access_token}`
                }
            }
        );
        return response.data;
    } catch (error: any) {
        console.error('Logout Error:', error.message || error);
        throw error;
    }
};


export const forgotPassword = async (values: ForgotPasswordData): Promise<ForgotPasswordResponse> => {
    const response = await axios.post<ForgotPasswordResponse>('https://api.nutient.com/api/accounts/forgot-password/', values);
    return response.data;
}
export const resetPassword = async (values: ResetPasswordData): Promise<ResetPasswordResponse> => {
    const response = await axios.post<ResetPasswordResponse>('https://api.nutient.com/api/accounts/reset-password/', values);
    return response.data;
}

