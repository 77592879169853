import axios from 'axios';

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    console.log(token)
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

export interface Meals {
    id: number;
    meal_name: string;
    meal_category: string;
    base_metric: string;
    base_weight: number;
    available_metrics: {
        [key: string]: number; // e.g. { cup: 0.33, grams: 50 }
    };
    base_nutrition: {
        fat: number;
        protein: number;
        calories: number;
        carbohydrates: number;
    };
    portion_size: number;
    portion_size_metric: string;
    created_at: string;
    updated_at: string;
}

export interface GetAllMealsData {
    access_token: string;
}
export interface GetAllMealsResponse extends Array<Meals> { }
export interface GetAMealResponse {
    id: number;
    meal_name: string;
    meal_category: string;
    base_metric: string;
    base_weight: number;
    available_metrics: {
        [key: string]: number;
    };
    base_nutrition: {
        fat: number;
        protein: number;
        calories: number;
        carbohydrates: number;
    };
    portion_size: number;
    portion_size_metric: string;
    created_at: string;
    updated_at: string;
}
export interface MealData {
    id: number;
    meal__meal_name: string;
    meal: number;
    meal_type: string;
    portion_size: number;
    portion_size_metric: string;
    meal_nutrition: {
        fat: number;
        protein: number;
        calories: number;
        carbohydrates: number;
    };
}
export interface CreateMealData {
    meal_logs: {
        meal: number;
        meal_type: string;
        portion_size: number;
        portion_size_metric: string;
        meal_nutrition: {
            fat: number;
            protein: number;
            calories: number;
            carbohydrates: number;
        };
    }[];
    water_logs: {
        amount_taken: number;
        unit: string;
    }[];
}

export interface CreateMealResponse {
    data: MealData;
    message: string;
}
export interface WaterData {
    amount_taken: number;
    unit: string;
}
export interface CreateWaterData {
    amount_taken: number;
    unit: string;
}
export interface CreateWaterResponse {
    data: WaterData;
    message: string;
}
export interface DailyMealLog {
    breakfast: MealData[],
    lunch: MealData[],
    dinner: MealData[],
    snack: MealData[],
}
export interface GetDailyMealLogData {
    id: number;
}
export interface GetDailyMealLogResponse {
    data: DailyMealLog;
    message: string;
}

export const getAllMeals = async (access_token: string, searchTerm: string | null): Promise<GetAllMealsResponse> => {
    const response = await axios.get<GetAllMealsResponse>(`https://api.nutient.com/api/meals/index/?search=${searchTerm}`, {
        headers: {
            Authorization: `Bearer ${access_token}`
        },
    });
    console.log('API Response:', response.data);
    return response.data;
}
export const getAMeal = async (access_token: string, id: string | null): Promise<GetAMealResponse> => {
    const response = await axios.get<GetAMealResponse>(`https://api.nutient.com/api/meals/${id}/`, {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    });
    return response.data;
}
export const createMeal = async (access_token: string, values: CreateMealData): Promise<CreateMealResponse> => {
    const response = await axios.post<CreateMealResponse>(`https://api.nutient.com/api/consumption-logs/batch-log/`, values, {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    });
    return response.data;
}
export const createWater = async (access_token: string, values: CreateWaterData): Promise<CreateWaterResponse> => {
    const response = await axios.post<CreateWaterResponse>(`https://api.nutient.com/api/consumption-logs/create-water/`, values, {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    });
    return response.data;
}
export const getDailyMealLog = async (access_token: string, id: string | null): Promise<GetDailyMealLogResponse> => {
    const response = await axios.get<GetDailyMealLogResponse>(`https://api.nutient.com/api/users/${id}/meal-logs`, {
        headers: {
            Authorization: `Bearer ${access_token}`
        }
    });
    return response.data;
}