import React, { useState } from 'react'
import Logo from "../../../assets/images/logo.png"
import { Container, Card, Row, Col, Image, Button } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CloseBtn from "../../../assets/images/close-btn.png"
import NextBtn from "../../../assets/images/next-btn.png"
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Select, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useUserContext } from '../../../context/user/user-context';
interface SignUpValues {
    first_name: string;
    last_name: string;
    email: string;
    sex_at_birth: string;
    country_of_residence: string;
    ethnicity: string;
    password: string;
    confirm_password: string;
    terms: boolean;
}

function SignUp() {
    const { updateUserDetails } = useUserContext();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    return (
        <Container fluid>
            <div className="auth-bg">
                <Card
                    className="auth-card text-center"
                >
                    <Link to="/" className='d-inline-flex justify-content-end'>
                        <img src={CloseBtn} alt="close" />
                    </Link>
                    <div className='text-center'>
                        <Image src={Logo} width={97} height={120} />
                    </div>
                    <h4 className="auth-text-1 mt-2">Create An Account</h4>
                    <div className='d-flex justify-content-center mt-3'>
                        <ProgressBar now={0} style={{ height: '4px', width: '296px' }} />
                    </div>
                    <div className='text-start mt-2'>
                        <Formik
                            initialValues={{
                                first_name: "",
                                last_name: "",
                                email: "",
                                sex_at_birth: "",
                                country_of_residence: "",
                                ethnicity: "",
                                password: "",
                                confirm_password: "",
                                terms: false,
                            }}
                            validationSchema={Yup.object().shape({
                                first_name: Yup.string().required("First name is required"),
                                last_name: Yup.string().required("Last name is required"),
                                email: Yup.string()
                                    .email("Invalid email")
                                    .required("Email is required"),
                                sex_at_birth: Yup.string().required("Sex is required"),
                                country_of_residence: Yup.string().required("Country is required"),
                                ethnicity: Yup.string().required("  Ethnicity is required"),
                                password: Yup.string()
                                    .required("No password provided.")
                                    .min(8, "Must be at least 8 characters"),
                                confirm_password: Yup.string()
                                    .oneOf([Yup.ref("password"), ""], "Passwords do not match")
                                    .required("Please confirm password."),
                                terms: Yup.boolean().oneOf(
                                    [true],
                                    "You must accept the terms and conditions",
                                ),

                            })}
                            onSubmit={(values: SignUpValues, { setSubmitting }) => {
                                console.log(values)
                                updateUserDetails(values);
                                navigate("/age");
                                setSubmitting(false);
                            }}
                            validateOnChange
                            validateOnBlur
                        >
                            {({
                                errors,
                                touched,
                                handleSubmit,
                                isSubmitting,
                                values,
                                handleChange,
                                setFieldValue,
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6} className="mt-3">
                                            <Field
                                                type="text"
                                                name="first_name"
                                                placeholder="First Name *"
                                                className={`input-field ${touched.first_name && errors.first_name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                            <ErrorMessage name="first_name" component="div" className="invalid-feedback" />
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <Field
                                                type="text"
                                                name="last_name"
                                                placeholder="Last Name *"
                                                className={`input-field ${touched.last_name && errors.last_name
                                                    ? "is-invalid"
                                                    : ""
                                                    }`}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mt-3">
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder="Email Address *"
                                                className={`input-field ${touched.email && errors.email ? "is-invalid" : ""
                                                    }`}
                                            />
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <FormControl fullWidth>
                                                <InputLabel className='dash-text-16'>Sex At Birth *</InputLabel>
                                                <Select
                                                    label="Sex At Birth"
                                                    name="sex_at_birth"
                                                    value={values.sex_at_birth}
                                                    onChange={handleChange}
                                                    className={`input-field ${touched.sex_at_birth && errors.sex_at_birth
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                >
                                                    <MenuItem value={"M"}>Male</MenuItem>
                                                    <MenuItem value={"F"}>Female</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mt-3">
                                            <FormControl fullWidth>
                                                <InputLabel className='dash-text-16'>Country *</InputLabel>
                                                <Select
                                                    label="Country"
                                                    name="country_of_residence"
                                                    value={values.country_of_residence}
                                                    onChange={handleChange}
                                                    className={`input-field ${touched.country_of_residence && errors.country_of_residence
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                >
                                                    <MenuItem value={""} disabled>Select Country</MenuItem>
                                                    <MenuItem value="AF">Afghanistan</MenuItem>
                                                    <MenuItem value="AL">Albania</MenuItem>
                                                    <MenuItem value="DZ">Algeria</MenuItem>
                                                    <MenuItem value="AS">American Samoa</MenuItem>
                                                    <MenuItem value="AD">Andorra</MenuItem>
                                                    <MenuItem value="AO">Angola</MenuItem>
                                                    <MenuItem value="AI">Anguilla</MenuItem>
                                                    <MenuItem value="AQ">Antarctica</MenuItem>
                                                    <MenuItem value="AG">Antigua and Barbuda</MenuItem>
                                                    <MenuItem value="AR">Argentina</MenuItem>
                                                    <MenuItem value="AM">Armenia</MenuItem>
                                                    <MenuItem value="AW">Aruba</MenuItem>
                                                    <MenuItem value="AU">Australia</MenuItem>
                                                    <MenuItem value="AT">Austria</MenuItem>
                                                    <MenuItem value="AZ">Azerbaijan</MenuItem>
                                                    <MenuItem value="BS">Bahamas</MenuItem>
                                                    <MenuItem value="BH">Bahrain</MenuItem>
                                                    <MenuItem value="BD">Bangladesh</MenuItem>
                                                    <MenuItem value="BB">Barbados</MenuItem>
                                                    <MenuItem value="BY">Belarus</MenuItem>
                                                    <MenuItem value="BE">Belgium</MenuItem>
                                                    <MenuItem value="BZ">Belize</MenuItem>
                                                    <MenuItem value="BJ">Benin</MenuItem>
                                                    <MenuItem value="BM">Bermuda</MenuItem>
                                                    <MenuItem value="BT">Bhutan</MenuItem>
                                                    <MenuItem value="BO">Bolivia</MenuItem>
                                                    <MenuItem value="BA">Bosnia and Herzegovina</MenuItem>
                                                    <MenuItem value="BW">Botswana</MenuItem>
                                                    <MenuItem value="BR">Brazil</MenuItem>
                                                    <MenuItem value="IO">British Indian Ocean Territory</MenuItem>
                                                    <MenuItem value="BN">Brunei Darussalam</MenuItem>
                                                    <MenuItem value="BG">Bulgaria</MenuItem>
                                                    <MenuItem value="BF">Burkina Faso</MenuItem>
                                                    <MenuItem value="BI">Burundi</MenuItem>
                                                    <MenuItem value="CV">Cabo Verde</MenuItem>
                                                    <MenuItem value="KH">Cambodia</MenuItem>
                                                    <MenuItem value="CM">Cameroon</MenuItem>
                                                    <MenuItem value="CA">Canada</MenuItem>
                                                    <MenuItem value="KY">Cayman Islands</MenuItem>
                                                    <MenuItem value="CF">Central African Republic</MenuItem>
                                                    <MenuItem value="TD">Chad</MenuItem>
                                                    <MenuItem value="CL">Chile</MenuItem>
                                                    <MenuItem value="CN">China</MenuItem>
                                                    <MenuItem value="CO">Colombia</MenuItem>
                                                    <MenuItem value="KM">Comoros</MenuItem>
                                                    <MenuItem value="CG">Congo</MenuItem>
                                                    <MenuItem value="CD">Congo (DRC)</MenuItem>
                                                    <MenuItem value="CR">Costa Rica</MenuItem>
                                                    <MenuItem value="CI">Côte d’Ivoire</MenuItem>
                                                    <MenuItem value="HR">Croatia</MenuItem>
                                                    <MenuItem value="CU">Cuba</MenuItem>
                                                    <MenuItem value="CW">Curaçao</MenuItem>
                                                    <MenuItem value="CY">Cyprus</MenuItem>
                                                    <MenuItem value="CZ">Czechia</MenuItem>
                                                    <MenuItem value="DK">Denmark</MenuItem>
                                                    <MenuItem value="DJ">Djibouti</MenuItem>
                                                    <MenuItem value="DM">Dominica</MenuItem>
                                                    <MenuItem value="DO">Dominican Republic</MenuItem>
                                                    <MenuItem value="EC">Ecuador</MenuItem>
                                                    <MenuItem value="EG">Egypt</MenuItem>
                                                    <MenuItem value="SV">El Salvador</MenuItem>
                                                    <MenuItem value="GQ">Equatorial Guinea</MenuItem>
                                                    <MenuItem value="ER">Eritrea</MenuItem>
                                                    <MenuItem value="EE">Estonia</MenuItem>
                                                    <MenuItem value="SZ">Eswatini</MenuItem>
                                                    <MenuItem value="ET">Ethiopia</MenuItem>
                                                    <MenuItem value="FJ">Fiji</MenuItem>
                                                    <MenuItem value="FI">Finland</MenuItem>
                                                    <MenuItem value="FR">France</MenuItem>
                                                    <MenuItem value="GA">Gabon</MenuItem>
                                                    <MenuItem value="GM">Gambia</MenuItem>
                                                    <MenuItem value="GE">Georgia</MenuItem>
                                                    <MenuItem value="DE">Germany</MenuItem>
                                                    <MenuItem value="GH">Ghana</MenuItem>
                                                    <MenuItem value="GR">Greece</MenuItem>
                                                    <MenuItem value="GD">Grenada</MenuItem>
                                                    <MenuItem value="GU">Guam</MenuItem>
                                                    <MenuItem value="GT">Guatemala</MenuItem>
                                                    <MenuItem value="GN">Guinea</MenuItem>
                                                    <MenuItem value="GW">Guinea-Bissau</MenuItem>
                                                    <MenuItem value="GY">Guyana</MenuItem>
                                                    <MenuItem value="HT">Haiti</MenuItem>
                                                    <MenuItem value="HN">Honduras</MenuItem>
                                                    <MenuItem value="HU">Hungary</MenuItem>
                                                    <MenuItem value="IS">Iceland</MenuItem>
                                                    <MenuItem value="IN">India</MenuItem>
                                                    <MenuItem value="ID">Indonesia</MenuItem>
                                                    <MenuItem value="IR">Iran</MenuItem>
                                                    <MenuItem value="IQ">Iraq</MenuItem>
                                                    <MenuItem value="IE">Ireland</MenuItem>
                                                    <MenuItem value="IL">Israel</MenuItem>
                                                    <MenuItem value="IT">Italy</MenuItem>
                                                    <MenuItem value="JM">Jamaica</MenuItem>
                                                    <MenuItem value="JP">Japan</MenuItem>
                                                    <MenuItem value="JO">Jordan</MenuItem>
                                                    <MenuItem value="KZ">Kazakhstan</MenuItem>
                                                    <MenuItem value="KE">Kenya</MenuItem>
                                                    <MenuItem value="KI">Kiribati</MenuItem>
                                                    <MenuItem value="KW">Kuwait</MenuItem>
                                                    <MenuItem value="KG">Kyrgyzstan</MenuItem>
                                                    <MenuItem value="LA">Laos</MenuItem>
                                                    <MenuItem value="LV">Latvia</MenuItem>
                                                    <MenuItem value="LB">Lebanon</MenuItem>
                                                    <MenuItem value="LS">Lesotho</MenuItem>
                                                    <MenuItem value="LR">Liberia</MenuItem>
                                                    <MenuItem value="LY">Libya</MenuItem>
                                                    <MenuItem value="LI">Liechtenstein</MenuItem>
                                                    <MenuItem value="LT">Lithuania</MenuItem>
                                                    <MenuItem value="LU">Luxembourg</MenuItem>
                                                    <MenuItem value="MG">Madagascar</MenuItem>
                                                    <MenuItem value="MW">Malawi</MenuItem>
                                                    <MenuItem value="MY">Malaysia</MenuItem>
                                                    <MenuItem value="MV">Maldives</MenuItem>
                                                    <MenuItem value="ML">Mali</MenuItem>
                                                    <MenuItem value="MT">Malta</MenuItem>
                                                    <MenuItem value="MH">Marshall Islands</MenuItem>
                                                    <MenuItem value="MQ">Martinique</MenuItem>
                                                    <MenuItem value="MR">Mauritania</MenuItem>
                                                    <MenuItem value="MU">Mauritius</MenuItem>
                                                    <MenuItem value="MX">Mexico</MenuItem>
                                                    <MenuItem value="FM">Micronesia</MenuItem>
                                                    <MenuItem value="MD">Moldova</MenuItem>
                                                    <MenuItem value="MC">Monaco</MenuItem>
                                                    <MenuItem value="MN">Mongolia</MenuItem>
                                                    <MenuItem value="ME">Montenegro</MenuItem>
                                                    <MenuItem value="MA">Morocco</MenuItem>
                                                    <MenuItem value="MZ">Mozambique</MenuItem>
                                                    <MenuItem value="MM">Myanmar</MenuItem>
                                                    <MenuItem value="NA">Namibia</MenuItem>
                                                    <MenuItem value="NR">Nauru</MenuItem>
                                                    <MenuItem value="NP">Nepal</MenuItem>
                                                    <MenuItem value="NL">Netherlands</MenuItem>
                                                    <MenuItem value="NZ">New Zealand</MenuItem>
                                                    <MenuItem value="NI">Nicaragua</MenuItem>
                                                    <MenuItem value="NE">Niger</MenuItem>
                                                    <MenuItem value="NG">Nigeria</MenuItem>
                                                    <MenuItem value="KP">North Korea</MenuItem>
                                                    <MenuItem value="MK">North Macedonia</MenuItem>
                                                    <MenuItem value="NO">Norway</MenuItem>
                                                    <MenuItem value="OM">Oman</MenuItem>
                                                    <MenuItem value="PK">Pakistan</MenuItem>
                                                    <MenuItem value="PW">Palau</MenuItem>
                                                    <MenuItem value="PA">Panama</MenuItem>
                                                    <MenuItem value="PG">Papua New Guinea</MenuItem>
                                                    <MenuItem value="PY">Paraguay</MenuItem>
                                                    <MenuItem value="PE">Peru</MenuItem>
                                                    <MenuItem value="PH">Philippines</MenuItem>
                                                    <MenuItem value="PL">Poland</MenuItem>
                                                    <MenuItem value="PT">Portugal</MenuItem>
                                                    <MenuItem value="PR">Puerto Rico</MenuItem>
                                                    <MenuItem value="QA">Qatar</MenuItem>
                                                    <MenuItem value="RO">Romania</MenuItem>
                                                    <MenuItem value="RU">Russia</MenuItem>
                                                    <MenuItem value="RW">Rwanda</MenuItem>
                                                    <MenuItem value="KN">Saint Kitts and Nevis</MenuItem>
                                                    <MenuItem value="LC">Saint Lucia</MenuItem>
                                                    <MenuItem value="VC">Saint Vincent and the Grenadines</MenuItem>
                                                    <MenuItem value="WS">Samoa</MenuItem>
                                                    <MenuItem value="SM">San Marino</MenuItem>
                                                    <MenuItem value="ST">Sao Tome and Principe</MenuItem>
                                                    <MenuItem value="SA">Saudi Arabia</MenuItem>
                                                    <MenuItem value="SN">Senegal</MenuItem>
                                                    <MenuItem value="RS">Serbia</MenuItem>
                                                    <MenuItem value="SC">Seychelles</MenuItem>
                                                    <MenuItem value="SL">Sierra Leone</MenuItem>
                                                    <MenuItem value="SG">Singapore</MenuItem>
                                                    <MenuItem value="SK">Slovakia</MenuItem>
                                                    <MenuItem value="SI">Slovenia</MenuItem>
                                                    <MenuItem value="SB">Solomon Islands</MenuItem>
                                                    <MenuItem value="SO">Somalia</MenuItem>
                                                    <MenuItem value="ZA">South Africa</MenuItem>
                                                    <MenuItem value="KR">South Korea</MenuItem>
                                                    <MenuItem value="SS">South Sudan</MenuItem>
                                                    <MenuItem value="ES">Spain</MenuItem>
                                                    <MenuItem value="LK">Sri Lanka</MenuItem>
                                                    <MenuItem value="SD">Sudan</MenuItem>
                                                    <MenuItem value="SR">Suriname</MenuItem>
                                                    <MenuItem value="SE">Sweden</MenuItem>
                                                    <MenuItem value="CH">Switzerland</MenuItem>
                                                    <MenuItem value="SY">Syria</MenuItem>
                                                    <MenuItem value="TW">Taiwan</MenuItem>
                                                    <MenuItem value="TJ">Tajikistan</MenuItem>
                                                    <MenuItem value="TZ">Tanzania</MenuItem>
                                                    <MenuItem value="TH">Thailand</MenuItem>
                                                    <MenuItem value="TL">Timor-Leste</MenuItem>
                                                    <MenuItem value="TG">Togo</MenuItem>
                                                    <MenuItem value="TO">Tonga</MenuItem>
                                                    <MenuItem value="TT">Trinidad and Tobago</MenuItem>
                                                    <MenuItem value="TN">Tunisia</MenuItem>
                                                    <MenuItem value="TR">Turkey</MenuItem>
                                                    <MenuItem value="TM">Turkmenistan</MenuItem>
                                                    <MenuItem value="TV">Tuvalu</MenuItem>
                                                    <MenuItem value="UG">Uganda</MenuItem>
                                                    <MenuItem value="UA">Ukraine</MenuItem>
                                                    <MenuItem value="AE">United Arab Emirates</MenuItem>
                                                    <MenuItem value="GB">United Kingdom</MenuItem>
                                                    <MenuItem value="US">United States</MenuItem>
                                                    <MenuItem value="UY">Uruguay</MenuItem>
                                                    <MenuItem value="UZ">Uzbekistan</MenuItem>
                                                    <MenuItem value="VU">Vanuatu</MenuItem>
                                                    <MenuItem value="VA">Vatican City</MenuItem>
                                                    <MenuItem value="VE">Venezuela</MenuItem>
                                                    <MenuItem value="VN">Vietnam</MenuItem>
                                                    <MenuItem value="YE">Yemen</MenuItem>
                                                    <MenuItem value="ZM">Zambia</MenuItem>
                                                    <MenuItem value="ZW">Zimbabwe</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <FormControl fullWidth>
                                                <InputLabel className='dash-text-16'>Ethnicity *</InputLabel>
                                                <Select
                                                    label="Ethnicity"
                                                    name="ethnicity"
                                                    value={values.ethnicity}
                                                    onChange={handleChange}
                                                    className={`input-field ${touched.ethnicity && errors.ethnicity ? "is-invalid" : ""
                                                        }`}
                                                >
                                                    <MenuItem value={""} disabled>Select Ethnicity</MenuItem>
                                                    <MenuItem value="asian">Asian</MenuItem>
                                                    <MenuItem value="black_african">Black / African</MenuItem>
                                                    <MenuItem value="hispanic_latino">Hispanic / Latino</MenuItem>
                                                    <MenuItem value="middle_eastern_north_african">Middle Eastern / North African</MenuItem>
                                                    <MenuItem value="native_american_alaskan">Native American / Alaskan Native</MenuItem>
                                                    <MenuItem value="native_hawaiian_pacific_islander">Native Hawaiian / Pacific Islander</MenuItem>
                                                    <MenuItem value="white_caucasian">White / Caucasian</MenuItem>
                                                    <MenuItem value="mixed_multi_racial">Mixed / Multi-Racial</MenuItem>
                                                    <MenuItem value="other">Other</MenuItem>
                                                    <MenuItem value="prefer_not_to_say">Prefer not to say</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mt-3">
                                            <div className="position-relative">
                                                <Field
                                                    type={showPassword ? "text" : "password"}
                                                    name="password"
                                                    placeholder="Password *"
                                                    className={`input-field ${touched.password && errors.password
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                />
                                                <span
                                                    className="password-toggle-icon"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col md={6} className="mt-3">
                                            <div className="position-relative">
                                                <Field
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    name="confirm_password"
                                                    placeholder="Confirm Password *"
                                                    className={`input-field ${touched.confirm_password && errors.confirm_password
                                                        ? "is-invalid"
                                                        : ""
                                                        }`}
                                                />
                                                <span
                                                    className="password-toggle-icon"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="mt-3 auth-text-2 ">
                                            <span>
                                                <Field type="checkbox" name="terms" />
                                                <span className='mx-2'>
                                                    I accept Nutrient's{" "}
                                                    <span className="text-color-1">Privacy </span> and{" "}
                                                    <span className="text-color-1"> Term of Use </span>
                                                </span>
                                            </span>
                                            {touched.terms && errors.terms && (
                                                <div className="invalid-feedback">{errors.terms}</div>
                                            )}
                                        </Col>
                                    </Row>
                                    <div className="text-center auth-text-2 mt-4">
                                        <Link to="/sign-in" className="link-styles">
                                            Already have an account?{" "}
                                            <span className="text-color-1"> Sign In </span>
                                        </Link>

                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <Button type='submit' className='border-0 bg-color-6'>
                                            <img src={NextBtn} alt="next" />
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>
        </Container>
    )
}

export default SignUp