import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
    SignUpData, SignUpResponse, signup,
    SignInData, SignInResponse, signin,
    SignOutResponse, signout,
    ForgotPasswordData, ForgotPasswordResponse, forgotPassword,
    ResetPasswordData, ResetPasswordResponse, resetPassword,
} from "../../services/auth/authService";

export interface AuthState {
    signUpResponse?: SignUpResponse | null;
    signInResponse?: SignInResponse | null;
    signOutResponse?: SignOutResponse | null;
    forgotPassword?: ForgotPasswordResponse | null;
    resetPassword?: ResetPasswordResponse | null;
    bmr?: number | null;
    userId?: number | null;
    email?: string;
    token?: string;
    accessToken?: string;
    refreshToken?: string;
    resetAuthState?: string;
}

const initialState: AuthState = {
    signUpResponse: null,
    signInResponse: null,
    signOutResponse: null,
    forgotPassword: null,
    resetPassword: null,
    bmr: null,
    email: "",
    token: "",
    accessToken: localStorage.getItem('accessToken') || "",
    refreshToken: localStorage.getItem('refreshToken') || "",
    resetAuthState: "",
}

export const signupAsync = createAsyncThunk(
    'accounts/signup',
    async (signUpData: SignUpData, { dispatch, rejectWithValue }) => {
        try {
            const response = await signup(signUpData);
            console.log('Signup response:', response);
            return response;
        } catch (error: any) {
            console.error('Signup error:', error);
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);
export const signinAsync = createAsyncThunk(
    'auth/signin',
    async (signInData: SignInData, { dispatch, rejectWithValue }) => {
        console.log('Sign-in attempt with data:', signInData); // Add this log
        try {
            const response = await signin(signInData);
            console.log('Signin response:', response);
            if (response.tokens && response.User) {
                const userEmail = response.User.email;
                const accessToken = response.tokens.access_token;
                const refreshToken = response.tokens.refresh_token;

                console.log('Access Token:', accessToken);
                console.log('Refresh Token:', refreshToken);

                localStorage.setItem('userSigninEmail', userEmail);
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                dispatch(setBmr(response.User.bmr));
                dispatch(setEmail(userEmail));
                dispatch(setAccessToken(accessToken));
                dispatch(setRefreshToken(refreshToken));
            }
            return response;

        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

export const signoutAsync = createAsyncThunk(
    'auth/signout',
    async (signOutData: { refresh_token: string; access_token: string }) => {
        const { refresh_token, access_token } = signOutData;
        const response = await signout({ refresh_token }, access_token);
        return response;
    }
);

export const forgotPasswordAsync = createAsyncThunk(
    'auth/forgotPassword',
    async (forgotPasswordData: ForgotPasswordData, { dispatch, rejectWithValue }) => {
        try {
            const response = await forgotPassword(forgotPasswordData);
            console.log('API Response:', response);
            if (response.data) {
                const resetToken = response.data.token;
                const email = forgotPasswordData.email;
                localStorage.setItem('resetToken', resetToken);
                localStorage.setItem('resetEmail', email);
                console.log('Email saved in local storage:', email);
                dispatch(setToken(resetToken));
                dispatch(setEmail(email));
            }
            return response;
        } catch (error: any) {
            console.error('API Error:', error);
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

export const resetPasswordAsync = createAsyncThunk(
    'auth/resetPassword',
    async (resetPasswordData: ResetPasswordData, { rejectWithValue }) => {
        try {
            const response = await resetPassword(resetPasswordData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload.tokens;
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload.tokens;
        },
        resetAuthState: (state) => {
            return { ...initialState };
        },
        setBmr: (state, action) => {
            state.bmr = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signupAsync.fulfilled, (state, action) => {
            state.signUpResponse = action.payload;
        });
        builder.addCase(signinAsync.fulfilled, (state, action) => {
            console.log('Signin fulfilled:', action.payload.tokens.access_token); // Add this log
            state.signInResponse = action.payload;
            if (action.payload) {
                state.email = action.payload.User.email;
                state.accessToken = action.payload.tokens.access_token;
                state.refreshToken = action.payload.tokens.refresh_token;
                state.userId = action.payload.User.id;
            }
        });
        builder.addCase(signoutAsync.fulfilled, (state, action) => {
            state.signOutResponse = action.payload;
        });
        builder.addCase(forgotPasswordAsync.fulfilled, (state, action: PayloadAction<ForgotPasswordResponse>) => {
            console.log('Forgot Password Fulfilled:', action.payload); // Log the fulfilled action
            state.forgotPassword = action.payload;
            if (action.payload.data) {
                state.email = action.payload.data.email;
            }
        });
        builder.addCase(resetPasswordAsync.fulfilled, (state, action) => {
            state.resetPassword = action.payload;
            if (action.payload.data) {
            }
        });
    }
})

export const { setEmail, setToken, setAccessToken, setRefreshToken, resetAuthState, setBmr } = authSlice.actions;
export default authSlice.reducer;
