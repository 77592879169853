import React, { useEffect } from 'react';
import PasswordIcon from '../../../assets/images/password-icon.png';
import { Container, Button, Image, Card } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { resetPasswordAsync } from '../../../slices/auth/authSlice';
import { useAppSelector, useAppDispatch } from "../../../store/hooks";

interface ResetPasswordFormValues {
    password: string;
    password_confirmation: string;
    email: string;
    token: string;
}

function ResetPassword() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    useEffect(() => {
        if (!email || !token) {
            console.error('Email or token is missing from URL');
        }
    }, [email, token]);
    console.log("reset-email:", email)
    console.log("reset-token:", token)
    const resetPasswordResponse = useAppSelector(
        (state: any) => state.auth.resetPasswordResponse
    );
    useEffect(() => {
        if (resetPasswordResponse && !resetPasswordResponse.error) {
            navigate('/reset-successful');
        }
    }, [resetPasswordResponse, navigate]);
    const initialValues: ResetPasswordFormValues = {
        password: '',
        password_confirmation: '',
        email: email || '',
        token: token || '',
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('No password provided.')
            .min(8, 'Password is too short - should be 8 chars minimum.'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), ""], 'Passwords do not match')
            .required('Please confirm password.'),
    });
    return (
        <Container fluid>
            <div className="auth-bg">
                <Card className='auth-card-4'>
                    <div className='text-center'>
                        <Image src={PasswordIcon} className='' />
                        <h1 className=' auth-text-1 my-2'>Reset password</h1>
                        <p className='auth-text-2'>
                            Enter your email address and we'll  <br />
                            send you a link to reset your password.
                        </p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values: ResetPasswordFormValues, { setSubmitting }) => {
                                console.log("Submitting email:", values);
                                dispatch(resetPasswordAsync({
                                    ...values,
                                    email: email || "",
                                    token: token || "",
                                }))
                            }}

                        >
                            {({ errors, touched, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className='text-center mt-2'>

                                        <Field
                                            type='password'
                                            name='password'
                                            placeholder='Password'
                                            className={`input-field-2 ${touched.password && errors.password ? 'is-invalid' : ''
                                                }`}
                                            required
                                        />
                                    </div>
                                    <div className='text-center mt-2'>

                                        <Field
                                            type='password'
                                            name='password_confirmation'
                                            placeholder='Re-Enter Password'
                                            className={`input-field-2 ${touched.password_confirmation && errors.password_confirmation ? 'is-invalid' : ''
                                                }`}
                                            required
                                        />
                                    </div>
                                    <div className='d-grid mt-3 sign-up-btn'>
                                        <Button type='submit'
                                            className='bg-color-1 border-0'
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                    <div className='auth-text-2 mt-3 pb-3'>
                                        <Link to='/sign-in' className='link-styles'>
                                            Back to <span className='text-color-1'> Sign in </span>
                                        </Link>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Card>
            </div>

        </Container>)
}

export default ResetPassword