import React, { useState } from "react";
import { Modal, Row, Col, Container, Button, Dropdown, Image, Form } from "react-bootstrap";
import { Formik } from 'formik';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Action from "../../../../../assets/images/action-vector.png";
import AddMore from "../../../../../assets/images/add-meal.png"
import { createMealAsync, getDailyMealLogAsync } from "slices/meal-logging/mealSlice";
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from "../../../../../store/store";
interface CreateMealValues {
    meal_logs: {
        meal: number;
        meal_type: string;
        portion_size: number;
        portion_size_metric: string;
        meal_nutrition: {
            fat: number;
            protein: number;
            calories: number;
            carbohydrates: number;
        };
    }[];
    water_logs: {
        amount_taken: number;
        unit: string;
    }[];
}
interface AddMealProps {
    closeModal: () => void;
    closeSnackModal: () => void;
    show: boolean;
    meals: { name: string; id: number; portion_size: number; portion_size_metric: string; protein: number; calories: number; fat: number; carbohydrates: number; }[];
    onAddMore: () => void;
    resetAddedMeals: () => void;
}

const AddMeal: React.FC<AddMealProps> = ({ closeModal, show, meals, onAddMore, closeSnackModal, resetAddedMeals }) => {
    const dispatch = useAppDispatch();
    const [dropdownOpen, setDropdownOpen] = useState<{
        [key: number]: boolean;
    }>({});
    const toggleDropdown = (index: number) => {
        setDropdownOpen((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };
    const access_token = useAppSelector((state: RootState) => state.auth.accessToken || "");
    const userId = useAppSelector((state: RootState) => state.auth.signInResponse?.User?.id);

    const handleSubmit = async (values: CreateMealValues) => {
        const mealData = meals.map((meal) => ({
            meal: meal.id,
            meal_type: "snack",
            portion_size: meal.portion_size,
            portion_size_metric: meal.portion_size_metric,
            meal_nutrition: {
                calories: meal.calories,
                protein: meal.protein,
                fat: meal.fat,
                carbohydrates: meal.carbohydrates,
            },
        }));
        await dispatch(createMealAsync({
            access_token: access_token,
            createMealData: {
                meal_logs: mealData,
                water_logs: values.water_logs
            },
        }));
        closeModal();
        closeSnackModal();
        resetAddedMeals();

        dispatch(getDailyMealLogAsync({ access_token, id: String(userId) }));

    };

    return (
        <Container fluid className="" >
            <Modal
                centered
                show={show}
                onHide={closeModal}
                style={{ maxHeight: "100vh", overflowY: "auto" }}
            >
                <Formik
                    initialValues={{
                        meal_logs: [
                            {
                                meal: 0,
                                meal_type: "snack",
                                portion_size: 0,
                                portion_size_metric: "",
                                meal_nutrition: {
                                    calories: 0,
                                    carbohydrates: 0,
                                    fat: 0,
                                    protein: 0,
                                },
                            }
                        ],
                        water_logs: [
                            {
                                amount_taken: 0,
                                unit: "l",
                            }
                        ],
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                        closeModal();
                    }}
                >
                    {({ errors, touched, handleSubmit, values, handleChange, handleBlur }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header closeButton className="text-start mt-2 px-5">
                                <h4 className="dash-text-9">Snacks</h4>
                            </Modal.Header>
                            <Modal.Body className='add-modals px-5'
                            >
                                <Row>
                                    {meals.length > 0 ? (
                                        meals.map((meal, index) => (
                                            <Row key={index} className='mt-3'>
                                                <Col md={10} sm={10} xs={10}>
                                                    <p className="dash-text-15 m-0 p-0">{meal.name}</p>
                                                    <small className="dash-text-14 text-color-6 "> {meal.portion_size} {meal.portion_size_metric} •  {meal.calories} Cal •  {meal.protein} Pro •  {meal.fat} Fat •  {meal.carbohydrates} Carbs</small>

                                                </Col>
                                                <Col md={2} sm={2} xs={2}>
                                                    <div className='float-end'>
                                                        <Dropdown show={dropdownOpen[index]} onToggle={() => toggleDropdown(index)}>
                                                            <Dropdown.Toggle className="dropdown-toggle" variant="transparent" style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                                                                <img src={Action} alt="Action" className="action-icon mb-3" style={{ margin: '0', padding: '0' }} />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                >
                                                                    Edit
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                >
                                                                    Delete
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))
                                    ) : (
                                        <p className="dash-text-15">No meals added yet.</p>
                                    )}
                                </Row>
                                <Row className="mt-4">
                                    <Col>
                                        <Button className='text-black border-0 bg-color-6 mx-0 px-0' onClick={() => {
                                            onAddMore();
                                            closeModal();
                                        }}>
                                            <span><Image src={AddMore} /> <span className="dash-text-13"> Add</span>  </span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer className='px-5'>
                                <Row className="text-center">
                                    <small>2456 / 1500</small>
                                    <div className='d-flex justify-content-center'>
                                        <ProgressBar now={80} className="custom-progress-bar-2" style={{ height: '4px', width: '380px' }} />
                                    </div>
                                    <p className='mb-0'>Calories</p>
                                </Row>
                                <Button className='save-btn' type='submit'>Save</Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </Container>
    )
}

export default AddMeal